/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Box, Button, Dialog, DialogContent } from "@mui/material";
import CircularProgress from "../../../components/Spinner";
import useSubmitTaskHandler from "../../../hooks/useSubmitTaskHandler";
import TaskMapping, { lmsforms } from "./TaskMapping";
import WizardButtons from "./WizardButtons";
import { taskTypesWithRequiredAttachment, tasksThatNeedsQueuing } from "./constants";

const preprocessingTask = "preprocessing";

export default function TaskEdit({
  action,
  taskType,
  studyType,
  client,
  setSubmitMessage,
  title,
  taskId,
  workflowId,
  workflowName,
  setTaskId,
  taskConfig,
  setTaskConfig,
  displayHeader,
  setShowDialog,
  isRequired,
  setIsRequired,
}) {
  const [csv, setCsv] = useState({});
  const [loading, setLoading] = useState(false);
  const CurrentForm = TaskMapping[taskType];
  const queuedTask = !!tasksThatNeedsQueuing.has(taskType);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const debounce = () => {
    setDisableSubmit(true);
    setTimeout(() => setDisableSubmit(false), 5000);
  };

  const submitHandler = useSubmitTaskHandler({
    id: taskId,
    setTaskId,
    action,
    client,
    studyType,
    taskType: taskType.indexOf("v2_") === 0 ? taskType.slice(3) : taskType,
    taskConfig,
    title,
    csv,
    setSubmitMessage,
    redirectToDetails: false,
    queuedTask,
    setLoading,
    setShowDialog: () => {},
  });
  const requiredInputAdded = taskTypesWithRequiredAttachment.has(taskType) ? csv.attachment : true;
  if (taskType === preprocessingTask) {
    return (
      <form onSubmit={(e) => { e.preventDefault(); submitHandler(true, e); }}>
        <h4>
          <b>{"File Type: "}</b>
          {taskConfig.file_type ? taskConfig.file_type : "None"}
        </h4>
        <h4>
          <b>{"File Attachment: "}</b>
          {taskConfig.attachment ? taskConfig.attachment : "None"}
        </h4>
        <WizardButtons
          step={null}
          onChange={() => { }}
          hasNext={false}
          hasPrev={false}
          onSave={(event) => submitHandler(false, event)}
          validated={requiredInputAdded}
        >
          {!workflowName && !workflowId && (
            !queuedTask
              ? (
                <Button type="submit" disabled={!requiredInputAdded || disableSubmit}>
                  Run
                </Button>
              )
              : (
                <Button type="submit" disabled={!requiredInputAdded || disableSubmit}>
                  Submit to Queue
                </Button>
              ))}
        </WizardButtons>
      </form>
    );
  }
  const handleChange = (event) => {
    if (workflowName) {
      localStorage.setItem("cloneWorkflowName", workflowName);
      localStorage.setItem("workflowId", workflowId);
    } else {
      localStorage.setItem("cloneWorkflowName", null);
      localStorage.setItem("workflowId", null);
    }
    submitHandler(false, event);
  };
  return (
    <CurrentForm
      formDataProp={taskConfig}
      setFormData={setTaskConfig}
      setTaskConfig={setTaskConfig}
      submitHandler={submitHandler}
      studyType={studyType}
      client={client}
      taskConfig={taskConfig}
      csv={csv}
      setCsv={setCsv}
      title={title}
      displayHeader={displayHeader}
      setIsRequired={setIsRequired}
      addToWorkflowRequired={!lmsforms.includes(taskType)}
    >
      <WizardButtons
        step={null}
        onChange={() => { }}
        hasNext={false}
        hasPrev={false}
        onSave={handleChange}
        validated={requiredInputAdded}
        // TODO: Look at why and fix
        // Passing taskConfig just to force a rerender
        // Otherwise the onSubmit/onSave state is sometimes out of sync
        // Weird react behavior?
        taskConfig={taskConfig}
      >
        {!workflowName && !workflowId && (
          !queuedTask
            ? (
              <Button type="submit" disabled={!requiredInputAdded || disableSubmit}>
                Run
              </Button>
            )
            : (
              <Button type="submit" disabled={!requiredInputAdded || disableSubmit}>
                Submit to Queue
              </Button>
            ))}
        <Box style={{ width: "100%", margin: 8, height: "50%" }}>
          {loading
            && (
              <Dialog
                open={loading}
                PaperProps={{ style: { backgroundColor: "transparent", boxShadow: "none" } }}
                maxWidth="xs"
              >
                <DialogContent>
                  <CircularProgress style={{ width: "50px", height: "50px" }} />
                </DialogContent>
              </Dialog>
            )}
        </Box>
      </WizardButtons>
    </CurrentForm>
  );
}
