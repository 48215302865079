/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Typography, Divider } from "@mui/material";
import { Formik } from "formik";
import { useSelector } from "react-redux";

import AddToWorkflow from "../../../components/AddToWorkflow";
import { convertArrayToOptions } from "../../../utils/dataTransformations";
import WizardButtons from "../../../views/tasks/create_wizard/WizardButtons";
import { FormContextProvider } from "./FormContext";

export const LMSForm = ({
  title,
  client,
  studyType,
  step,
  setStep,
  submitHandler,
  children,
  validationSchema,
  initialValues,
  taskConfig,
  requiredInputAdded,
  addToWorkflowRequired = true,
}) => {
  const formOptions = useSelector((state) => state.formOptions);
  const lmsBackendClient = formOptions.lms_backend_clients?.find((c) => c.mnemonic?.toLowerCase() === client?.toLowerCase());
  const quarters = convertArrayToOptions(formOptions.quarters?.filter((q) => q !== ""));
  const [loading, setLoading] = useState(false);
  const [isRequired, setIsRequired] = useState([]);
  const isWorkflowTask = localStorage.getItem("cloneWorkflowName") === "null" ? null : localStorage.getItem("cloneWorkflowName");

  useEffect(() => {
    const fieldsRequiredFields = [];
    localStorage.setItem("form_type", "lms_form");
    Object.entries(validationSchema.fields).map((item) => {
      if (item[1]?.exclusiveTests?.required === true) {
        fieldsRequiredFields.push(item[0]);
      }
      if ((item[1].type === "object") && Object.entries(item[1].fields)[0][1]?.exclusiveTests?.required === true) {
        fieldsRequiredFields.push(item[0]);
      }
      return true;
    });

    setIsRequired((prevState) => ([...prevState, ...fieldsRequiredFields]));
  }, [setIsRequired, validationSchema.fields]);

  return (
    <div>
      <FormContextProvider
        clientMnemonic={lmsBackendClient?.mnemonic.toUpperCase()}
        studyType={studyType}
      >
        <Formik
          initialValues={{
            client,
            study: studyType,
            production_cycle: quarters?.length > 0 ? quarters[0]?.id : "",
            ...initialValues,
            ...taskConfig,
          }}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {
            (formik) => (
              <>
                <Typography variant="h3">
                  {`${title}: ${(lmsBackendClient?.mnemonic || client).toUpperCase()}`}
                </Typography>
                <Divider />
                {children}
                <WizardButtons
                  step={step}
                  onChange={setStep}
                  hasNext={step < 2}
                  onSave={(event) => submitHandler(false, event, formik.values)}
                  isWorkflowTask
                >
                  {!isWorkflowTask
                  && (
                    <>
                      <Button
                        type="submit"
                        onClick={(event) => {
                          formik.validateForm().then((errors) => {
                            const possibleErrors = Object.keys(errors);
                            if (possibleErrors && possibleErrors.length > 0) {
                              formik.setTouched({ ...formik.touched, ...errors });
                            } else {
                              submitHandler(true, event, formik.values);
                            }
                          });
                        }}
                      >
                        Run
                      </Button>
                      {addToWorkflowRequired && (
                        <AddToWorkflow
                          loading={loading}
                          isRequired={isRequired}
                          requiredInputAdded={requiredInputAdded}
                          submitHandler={submitHandler}
                          taskConfig={formik.values}
                        />
                      )}
                    </>
                  )}
                </WizardButtons>
              </>
            )
          }
        </Formik>
      </FormContextProvider>
    </div>
  );
};
