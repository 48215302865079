/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from "react";
import { AttachmentOutlined as AttachmentIcon, BlockOutlined as BlockIcon, Save as SaveIcon, Delete as DeleteIcon, ArrowBackTwoTone as ArrowBackIcon } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Chip,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Typography, Box,
  Dialog, DialogTitle, DialogContent, DialogActions, Card, CardContent, Tooltip,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate, useParams } from "react-router-dom";
import TableLoadingOverlay from "../../components/MaterialTableLoadingOverlay";
import MaterialTable from "../../components/Table";
import usePageTitle from "../../hooks/usePageTitle";
import { API } from "../../utils/api";
import PageNotFound from "../404";
import TaskCreateWizard from "../tasks/create_wizard";
import { tasksThatNeedsQueuing, taskTypesWithRequiredAttachment } from "../tasks/create_wizard/constants";

const useStyles = makeStyles({
  wrappedText: {
    maxWidth: "1000px",
    textAlign: "center",
    wordWrap: "break-word",
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DisplayTaskTitle = ({ rowData, setSubmitMessage, workflowNameParam }) => {
  const { workflowId, taskId, title } = rowData;
  const [showDialog, setShowDialog] = useState(false);

  const handleClose = () => {
    setShowDialog(false);
    localStorage.removeItem("cloneWorkflowName");
    localStorage.removeItem("form_type");
  };

  const handleClick = () => {
    localStorage.setItem("cloneWorkflowName", workflowNameParam);
    setShowDialog(true);
  };

  return showDialog ? (
    <TaskCreateWizard
      showDialog={showDialog}
      setShowDialog={handleClose}
      workflowId={workflowId}
      taskId={taskId}
      setSubmitMessage={setSubmitMessage}
    />
  ) : (
    <Button
      onClick={handleClick}
      variant="outlined"
      style={{
        width: 400,
        textAlign: "center",
        fontSize: "15px",
        backgroundColor: "white",
        color: "black",
      }}
    >
      {title}
    </Button>
  );
};

function EditWorkflow({ setSubmitMessage }) {
  const [workflowDetails, setWorkflowDetails] = useState({ workflowName: "", workflowId: "", workflowStatus: "CREATED" });
  const [tasks, setTasks] = useState([]);
  const [resultCount, setResultCount] = useState(0);
  const [notification, setNotification] = useState({
    success: false,
    error: false,
    deleted: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [missingStep, setMissingStep] = useState([]);
  const [missingAttachment, setMissingAttachment] = useState([]);
  const [pageLoadError, setPageLoadError] = useState(false);
  const [reload, setReload] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const { workflowNameParam } = useParams();
  const memoizedLoadingOverlay = useMemo(() => <TableLoadingOverlay />, []);
  const navigate = useNavigate();
  const classes = useStyles();

  usePageTitle("Edit Workflow");

  const handleRowDelete = (rowData) => {
    setRowToDelete(rowData);
    setConfirmOpen(true);
  };

  const handleConfirmDelete = () => {
    setIsLoading(true);
    API.request({
      url: `/delete-workflow-task/${rowToDelete.workflowId}/${rowToDelete.taskId}`,
      method: "DELETE",
    })
      .then((response) => {
        if (response.status === 204) {
          setReload(!reload);
          setNotification(prev => ({ ...prev, deleted: true }));
        }
      })
      .catch((error) => {
        console.error(error);
        setReload(!reload);
      });
    setConfirmOpen(false);
  };

  const handleSave = () => {
    setMissingStep([]);
    setMissingAttachment([]);
    const workflowTasks = [];
    const missingAttachments = [];
    const stepNumbers = new Set(tasks.map((task) => task.stepNumber));
    const maxStepNumber = Math.max(...stepNumbers);
    const missingSteps = [...Array(maxStepNumber).keys()]
      .map((x) => x + 1)
      .filter((i) => !stepNumbers.has(i));
    tasks.forEach((task) => {
      const isQueueNeeded = tasksThatNeedsQueuing.has(task.taskType);
      workflowTasks.push({
        task_id: task.taskId,
        is_blocking: task.isBlocking,
        step_number: task.stepNumber,
        is_queue: isQueueNeeded,
      });
      // eslint-disable-next-line no-unused-expressions
      taskTypesWithRequiredAttachment.has(task.taskType) && !task.hasAttachment && missingAttachments.push(task.title);
    });
    const updateTasksPayload = {
      workflow_tasks: workflowTasks,
      workflow_id: workflowDetails.workflowId,
    };
    if (missingSteps.length > 0) {
      setNotification(prev => ({ ...prev, error: true }));
      setMissingStep(missingSteps);
      console.error(
        `Error: Step number ${missingSteps.join(" and ")} ${missingSteps.length > 1 ? "are" : "is"
        } missing`,
      );
    } else if (missingAttachments.length > 0) {
      setNotification(prev => ({ ...prev, error: true }));
      setMissingAttachment(missingAttachments);
      console.error(
        `Error: Attachment(s) for : ${missingAttachments.join(" and ")} ${missingAttachments.length > 1 ? "are" : "is"
        } missing`,
      );
    } else {
      setIsLoading(true);
      API.request({
        url: "/update-task-step",
        method: "POST",
        data: updateTasksPayload,
      }).then((response) => {
        if (response.status === 200) {
          setReload(!reload);
          setNotification(prev => ({ ...prev, success: true }));
        }
      }).catch(error => {
        console.error(error);
      });
    }
  };

  useEffect(() => {
    API.request({
      url: "/workflow-tasks?limit=100",
      method: "GET",
      params: { workflow_name: workflowNameParam },
    })
      .then((response) => {
        setResultCount(response.data.resultCount);
        const updatedTasks = response.data.taskDetails.map((task) => ({
          ...task,
          stepNumber: task.stepNumber,
          isBlocking: task.isBlocking ? task.isBlocking : false,
        }));
        updatedTasks.sort((a, b) => (a.stepNumber > b.stepNumber ? 1 : -1));
        setTasks(updatedTasks);
        setWorkflowDetails({ workflowName: response.data.workflowDetails.workflowName, workflowId: response.data.workflowDetails.workflowId, workflowStatus: response.data.workflowDetails.status });
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setPageLoadError(true);
      });
  }, [workflowNameParam, reload]);

  const columns = [
    {
      header: "Task",
      accessorKey: "taskType",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      sorting: true,
      render: (rowData) => <DisplayTaskTitle rowData={rowData} setSubmitMessage={setSubmitMessage} workflowNameParam={workflowNameParam} />,
    },
    {
      header: "Client",
      accessorKey: "client",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <Chip
          variant="outlined"
          style={{
            width: 200,
            textAlign: "center",
            fontSize: "15px",
            backgroundColor: "white",
            color: "black",
          }}
          label={rowData.client ? rowData.client : "None"}
        />
      ),
    },
    {
      header: "Step Number",
      accessorKey: "stepNumber",
      render: (rowData) => (
        <Select
          value={rowData.stepNumber}
          onChange={(e) => {
            const updatedRowData = {
              ...rowData,
              stepNumber: parseInt(e.target.value, 10),
            };
            const updatedTasks = tasks.map((task) => (task.taskId === updatedRowData.taskId ? updatedRowData : task));
            updatedTasks.sort((a, b) => (a.stepNumber > b.stepNumber ? 1 : -1));
            setTasks(updatedTasks);
          }}
          variant="outlined"
          style={{ height: "40px" }}
        >
          {[...Array(resultCount)].map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem key={index} value={index + 1}>
              {index + 1}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      header: "Approval on Failure",
      accessorKey: "isBlocking",
      type: "boolean",
      headerStyle: { textAlign: "center" },
      cellStyle: { textAlign: "center" },
      sorting: false,
      render: (rowData) => (
        <Checkbox
          color="primary"
          size="medium"
          checked={rowData.isBlocking}
          onChange={() => {
            const updatedRowData = {
              ...rowData,
              isBlocking: !rowData.isBlocking,
            };
            const updatedTasks = tasks.map((task) => (task.taskId === updatedRowData.taskId ? updatedRowData : task));
            setTasks(updatedTasks);
          }}
        />
      ),
    },
    {
      header: "Queuing Task",
      accessorKey: "isQueued",
      type: "boolean",
      headerStyle: { textAlign: "center" },
      cellStyle: { textAlign: "center" },
      sorting: false,
      render: (rowData) => (
        <Checkbox
          disabled
          color="primary"
          size="medium"
          checked={tasksThatNeedsQueuing.has(rowData.taskType)}
        />
      ),
    },
    {
      header: "Attachment",
      accessorKey: "attachment",
      headerStyle: { textAlign: "center" },
      cellStyle: { textAlign: "center" },
      sorting: false,
      render: (rowData) => (
        taskTypesWithRequiredAttachment.has(rowData.taskType) ? (
          rowData.hasAttachment
            ? (
              <Tooltip title="Attachment Present" arrow>
                <AttachmentIcon color="secondary" />
              </Tooltip>
            )
            : (
              <Tooltip title="Please Upload attachment" arrow>
                <AttachmentIcon />
              </Tooltip>
            )
        )
          : (
            <Tooltip title="Not needed" arrow>
              <BlockIcon />
            </Tooltip>
          )
      ),
    },
    {
      header: "Actions",
      sorting: false,
      render: (rowData) => (
        <IconButton color="primary" onClick={() => handleRowDelete(rowData)} size="large">
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  if (pageLoadError) {
    return <PageNotFound />;
  }

  if (workflowDetails.workflowStatus !== "DRAFT" && workflowDetails.workflowStatus !== "CREATED") {
    return <PageNotFound />;
  }

  return (
    <div>
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Delete Task</DialogTitle>
        <DialogContent>Are you sure you want to delete this task?</DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirmDelete}>Confirm</Button>
        </DialogActions>
      </Dialog>

      <Card style={{ marginBottom: "10px", marginTop: "10px" }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/status", { state: { selectedTab: "analystWorkflow" } })}
              style={{ minWidth: "110px", textTransform: "none", marginTop: "10px" }}
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
            <Typography variant="h4" justifyContent="center" alignItems="center" className={classes.wrappedText}>
              {workflowDetails.workflowName}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSave}
              style={{ minWidth: "130px", textTransform: "none", marginRight: "10px" }}
              disabled={tasks.length <= 0}
              startIcon={<SaveIcon />}
            >
              Save Workflow
            </Button>
          </Box>
        </CardContent>
      </Card>
      {tasks.length > 0
        ? (
          <MaterialTable
            title="List of tasks in workflow:"
            columns={columns}
            data={tasks}
            isLoading={isLoading}
            options={{
              paging: false,
              search: true,
              searchFieldAlignment: "right",
              emptyRowsWhenPaging: false,
              showEmptyDataSourceMessage: tasks.length === 0,
              headerStyle: {
                backgroundColor: "#30455c",
                color: "#97a9bc",
              },
              header: {
                flexGrow: 1,
                textAlign: "center",
              },

            }}
            components={{
              OverlayLoading: () => memoizedLoadingOverlay,
            }}
          />
        ) : isLoading
          ? <TableLoadingOverlay />
          : (
            <Typography variant="h4" align="center" component="h4">
              <p>🛒</p>
              No Tasks in the selected Workflow
            </Typography>
          )}

      {
        notification.error && (
          missingStep.length > 0
          && (
            <Snackbar
              open={notification.error}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              autoHideDuration={3000}
              onClose={() => {
                setNotification({ success: false, error: false, deleted: false });
              }}
            >
              <div>
                <Alert severity="error">
                  {`Step number ${missingStep.join(" and ")} ${missingStep.length > 1 ? "are" : "is"} missing`}
                </Alert>
              </div>
            </Snackbar>
          )

        )
      }
      {
        notification.error && (
          missingAttachment.length > 0
          && (
            <Snackbar
              open={notification.error}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              autoHideDuration={3000}
              onClose={() => {
                setNotification({ success: false, error: false, deleted: false });
              }}
            >
              <div>
                <Alert severity="error">
                  {`Attachment for : ${missingAttachment.join(" and ")} ${missingAttachment.length > 1 ? "are" : "is"} missing`}
                </Alert>
              </div>
            </Snackbar>
          )

        )
      }
      {
        notification.success && (
          <Snackbar
            open={notification.success}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={3000}
            onClose={() => {
              setNotification({ success: false, error: false, deleted: false });
            }}
          >
            <div><Alert severity="success">Workflow Saved</Alert></div>
          </Snackbar>
        )
      }
      {
        notification.deleted && (
          <Snackbar
            open={notification.deleted}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={3000}
            onClose={() => {
              setNotification({ success: false, error: false, deleted: false });
            }}
          >
            <div><Alert severity="error">Task Deleted</Alert></div>
          </Snackbar>
        )
      }
    </div>
  );
}

export default EditWorkflow;
